<template>
    <section class="section statistics-section">
        <div class="wrapper">
            <StatBlock value="100K+" label="Items Available" />
            <StatBlock value="24/7" label="Expert Support" />
            <StatBlock value="10M+" label="Completed Transactions" />
            <StatBlock value="100%" label="Secure Transactions" />
        </div>
    </section>
</template>

<script>
import StatBlock from "../components/StatBlock.vue";
export default {
    name: 'StatSection',
    components: {
        StatBlock
    },
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>
.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 223px);
    justify-content: space-between;
    gap: 12px;
}

.stat-block {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #46566A;
    background-color: #1E2949;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;
    height: 122px;
}

.stat-value {
    font-family: 'Manrope', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    padding: 0 10px;
    border-radius: 4px;
    color: #19D6CC;
    min-width: 119px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    max-width: 208px;
}

@media (max-width: 900px) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .stat-block {}

    .stat-value {
        font-size: 32px;
        line-height: 40px;
    }

    .stat-label {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 460px) {
    .wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>