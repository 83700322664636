<template>
  <div class="modal order-modal">
    <div
      class="overlay"
      @click="$emit('closeOrderModal')"
    />
    <div class="wrapper">
      <div class="container">
        <img
          src="@/assets/img/close.svg"
          @click="$emit('closeOrderModal')"
          class="close"
        />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">

          <div
            v-if="isFetchingPaymentMethods"
            class="spinner-wrapper"
          >
            <div class="dual-ring"></div>
          </div>

          <div
            class="button-list"
            v-else-if="paymentMethods && paymentMethods.length"
          >
            <button
              v-for="(item, i) in paymentMethods"
              class="img-btn"
              @click="submit(item.code)"
              :key="i"
            >
              <div class="image-wrapper">
                <img
                  :src="item.image"
                  class="img"
                />
              </div>
              <div class="payment-method-title">{{ $t(item.title) }}</div>
            </button>
          </div>

          <div
            class="desc"
            v-else-if="!isFetchingPaymentMethods && !paymentMethods.length && !error"
          >
            {{ $t("There are no payment methods available") }}
          </div>

          <Transition>
            <div
              class="desc red"
              v-if="error"
            >{{ error }}</div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'OrderModal',
  props: {
    paymentMethods: {
      type: Array,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    orderData: {
      type: Object,
      required: true
    },
    isFetchingPaymentMethods: {
      type: Boolean,
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
  }
}
</script>
<style scoped>
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dual-ring,
.dual-ring:after {
  box-sizing: border-box;
}

.dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid white;
  border-color: white transparent white transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
