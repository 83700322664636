<template>
    <div class="news-index">
        <h1 class="news-title">{{ $t('News') }}</h1>
        <div class="news-grid">
            <div v-for="(elem, index) in news" :key="elem.id"
                :class="['new-card', { 'featured': index === 0, 'medium': index === 1 || index === 2 }]">
                <a :href="elem.external_url" target="_blank" class="new-link">
                    <div class="new-image" :style="{ backgroundImage: `url(${elem.image_url})` }"></div>
                    <div class="new-content">
                        <p class="new-date">{{ elem.date }}</p>
                        <h3 class="new-title">{{ elem.title }}</h3>
                        <p class="new-excerpt" v-html="elem.content"></p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'NewsIndex',
    data() {
        return {
            news: [],
        };
    },
    computed: {
        ...mapGetters('app', ['currentLanguage']),
    },
    mounted() {
        this.fetchnews();
    },
    watch: {
        currentLanguage() {
            this.fetchnews();
        }
    },
    methods: {
        fetchnews() {
            this.$http.get(`${process.env.VUE_APP_API}news`, {
                params: {
                    // limit: 5,
                    lang_id: this.currentLanguage,
                },
            })
                .then(response => {
                    this.news = response.data.payload.slice(0, 5);
                })
                .catch(error => {
                    console.error('Error fetching news data:', error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.news-index {
    padding: 40px 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.news-title {
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 40px;
    text-align: center;
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.new-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    &.featured {
        grid-column: 1 / -1;

        .new-image {
            height: 400px;
        }
    }

    &.medium {
        // No specific grid-column here 
    }
}

.new-link {
    text-decoration: none;
    color: #ffffff;
}

.new-image {
    height: 200px;
    background-size: cover;
    background-position: center;
}

.new-content {
    padding: 20px;
}

.new-date {
    font-size: 14px;
    color: #19D6CC;
    margin-bottom: 10px;
}

.new-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.new-excerpt {
    font-size: 14px;
    line-height: 1.5;
    opacity: 0.8;
}

@media (max-width: 1024px) {
    .news-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .new-card.featured {
        // Still spans full width 
    }

    .new-card.medium {
        grid-column: span 1;
    }
}

@media (max-width: 768px) {
    .news-grid {
        grid-template-columns: 1fr;
    }

    .new-card {
        // All cards span full width 
    }
}
</style>