<template>
    <section class="section section-unleash">
        <div class="wrapper">
            <img :src="require('@/assets/img/unleash-ellipse.png')" class="unleash-ellipse">

            <div class="content">
                <div class="text-content">
                    <h2 class="title">Unleash Your Style</h2>
                    <p class="subtitle">Buy TF and CS2 items in one place.</p>
                    <router-link to="/products/all" class="button primary">Browse more</router-link>
                </div>
                <img :src="require('@/assets/img/unleash.png')" alt="Unleash Your Style" class="unleash-image">
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.section-unleash {}

.wrapper {
    position: relative;
    color: white;
}

.unleash-ellipse {
    position: absolute;
    right: 0;
}

.content {
    background: #1E2949;
    border-radius: 8px;
    border: 1px solid #46566A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 32px 28px 32px;
}

.text-content {
    width: 435px;
}

.title {
    font-size: clamp(1.75rem, 1.393rem + 1.786vw, 3rem); // 28-48px, 320px -1440px
    font-weight: 700;
    line-height: 65.57px;
    text-align: left;
    margin-bottom: 12px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 20px;
}

.unleash-image {
    position: absolute;
    top: 0;
    right: 47px;
}


@media (max-width: 900px) {
    img {
        display: none;
    }

    .content,
    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title,
    .subtitle {
        text-align: center;
    }

    .button {
        align-self: center;
    }
}
</style>