<template>
  <section class="section features-section">
    <div class="wrapper">
      <h2 class="section-title title big">All in One Place</h2>
      <div class="cards-container">
        <div class="card">
          <div class="icon"><img :src="require('@/assets/img/feature-icon-left.svg')" alt=""></div>
          <h3 class="card-title">Extensive Collection</h3>
          <p class="card-subtitle">Discover a diverse range of in-game items to elevate your gaming experience.</p>
        </div>
        <div class="card">
          <div class="icon"><img :src="require('@/assets/img/feature-icon-middle.svg')" alt=""></div>
          <h3 class="card-title">Secure Transactions</h3>
          <p class="card-subtitle">Benefit from a platform built on trust and security, ensuring your purchases are
            safe.</p>
        </div>
        <div class="card">
          <div class="icon"> <img :src="require('@/assets/img/feature-icon-right.svg')" alt=""></div>
          <h3 class="card-title">Fast Access</h3>
          <p class="card-subtitle">Get immediate access to your new items with our quick and reliable delivery system.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.features-section {
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section-title {
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
  gap: 20px;
}

.card {
  padding: 24px;
  border-radius: 8px;
  background: #1E2949;
  border: 1px solid #46566A;
  display: flex;
  flex-direction: column;

  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 32px;
  }

  .card-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  .card-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #D3D7DC;
  }
}

@media (max-width: 600px) {}
</style>