<template>
    <div class="faq-section">
        <h2 class="title big">{{ title }}</h2>
        <div class="accordion">
            <div class="items-container">
                <div class="item" v-for="(item, i) in sortedFaq" :key="i" @click="togglePanel(i)"> 
                    <div class="panel">
                        <div :class="['desc big', { 'active': isActivePanel(i) }]">{{ item.question }}</div>
                        <img src="./../assets/img/arrow-down.svg" :class="['arrow', { 'active': isActivePanel(i) }]" />
                    </div>
                    <div ref="preview" :class="['preview', { 'active': isActivePanel(i) }]" :style="getPanelStyle(i)">
                        <div class="desc" v-html="item.answer"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaqSection',
    props: {
        title: {
            type: String,
            required: true,
        },
        faq: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            activePanel: null,
            previewHeights: [],
        };
    },
    computed: {
        sortedFaq() {
            return [...this.faq].sort((a, b) => a.order - b.order); 
        },
    },
    mounted() {
        this.calculatePreviewHeights();
    },
    methods: {
        togglePanel(index) {
            this.activePanel = this.activePanel === index ? null : index;
        },
        isActivePanel(index) {
            return this.activePanel === index;
        },
        getPanelStyle(index) {
            return this.isActivePanel(index) ? { maxHeight: Math.min(this.previewHeights[index], 250) + 'px' } : { maxHeight: '0' };
        },
        calculatePreviewHeights() {
            this.$nextTick(() => {
                this.previewHeights = this.$refs.preview.map(preview => preview.scrollHeight);
            });
        },
    },
};
</script>

<style scoped lang="scss">
.faq-section .accordion {
    margin-top: 40px;
}

.faq-section .accordion .items-container {
    border-radius: 8px;
    border: 1px solid #46566A;
    overflow: hidden;
}

.faq-section .accordion .item {
    background: #1E2949;
    padding: 24px;
    min-height: 80px;
    cursor: pointer;
}

.faq-section .accordion .item+.item {
    border-top: 1px solid #46566A;
}

.faq-section .accordion .panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-section .accordion .preview {
    overflow-y: auto;
    transition: max-height 0.3s ease, margin-top 0.3s ease;
    max-height: 0;
    margin-top: 0;
}

.faq-section .accordion .preview.active {
    max-height: max-content;
    margin-top: 16px;
}



.faq-section .accordion .preview .desc :deep(ul) {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style-type: disc;
    padding-left: 20px;
}

.faq-section .accordion .preview .desc :deep(li) {
    // margin-bottom: 10px;
}

.faq-section .accordion .arrow {
    transition: transform 0.3s ease;
}

.faq-section .accordion .arrow.active {
    transform: scaleY(-1);
}

.faq-section .accordion .desc.big {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #FFFFFF;
    border-radius: 4px;
}

.faq-section .accordion .desc.big.active {
    color: #19D6CC;
}
</style>