<template>
    <section class="section home-products-section product-carousel-section">
        <div class="wrapper">
            <div class="header">
                <h2 class="section-title title">{{ title }}</h2>
                <router-link :to="showAllLink" class="view-all-link">{{ $t('Show all') }}</router-link>
            </div>
            <Transition>
                <div v-if="products && products.length" class="">
                    <swiper :slides-per-view="'auto'" :space-between="15" :free-mode="true">
                        <swiper-slide v-for="(item, i) in products" :key="i">
                            <ProductCard :item="item" :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
                        </swiper-slide>
                    </swiper>
                </div>
            </Transition>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';

export default {
    name: 'ProductCarousel',
    components: {
        ProductCard,
        Swiper,
        SwiperSlide,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        showAllLink: {
            type: String,
            default: '/products/all',
        },
        apiEndpoint: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            products: [],
        };
    },
    computed: {
        ...mapGetters('app', ['currencyCode', 'currencySymbol'])
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        fetchProducts() {
            this.$http.get(process.env.VUE_APP_API + this.apiEndpoint + '?currency=' + this.currencyCode)
                .then((res) => {
                    this.products = res.data.payload;
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.openSignInModal();
                    }
                });
        },
        goToProduct(item) {
            this.$emit('goToProduct', item);
        },
    },
};
</script>

<style scoped lang="scss">
.product-carousel-section {
    color: #FFFFFF;

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .header {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .view-all-link {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        color: #2591B8;
        text-decoration: none;
    }

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        width: 228px;
    }
}
</style>