<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = '//code.tidio.co/lnheabzdtae8sr1yendifbnai2fsg2kg.js';
        script.async = true;
        document.body.appendChild(script);
    }
}
</script>