<template>
    <section class="section section-secure">
        <div class="wrapper">
            <div class="content">
                <img src="../assets/img/secure-ellipse.png" class="ellipse-background">
                <div class="ellipsis-container">
                    <div class="ellipsis-row">
                        <div class="ellipse">
                            <img src="../assets/img/secure-top-left.png" alt="Ellipse Image" class="ellipse-image">
                            <div class="ellipse-text">P250 | Nuclear Threat</div>
                        </div>
                        <div class="ellipse">
                            <img src="../assets/img/secure-top-right.png" alt="Ellipse Image" class="ellipse-image">
                            <div class="ellipse-text">Shadow Daggers | Gamma Doppler</div>
                        </div>
                    </div>
                    <div class="ellipsis-row">
                        <div class="ellipse">
                            <img src="../assets/img/secure-bottom.png" alt="Ellipse Image" class="ellipse-image">
                            <div class="ellipse-text">M4A4 | Poseidon</div>
                        </div>
                    </div>
                </div>
                <div class="text-content">
                    <h2 class="title">Secure and Affordable</h2>
                    <p class="subtitle">Benefit from competitive prices and get desired items at the best rates with our
                        secure purchasing methods.</p>
                    <router-link to="/products/all" class="button primary">Browse more</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.section-secure {}

.wrapper {
    display: flex;
    align-items: center;
    position: relative;
}


.ellipse-background {
    position: absolute;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    left: 0;
    bottom: 0;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 32px;
    gap: 20px;
    border-radius: 8px;
    background: #1E2949;
    border: 1px solid #46566A;
    position: relative;
}

.ellipsis-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 0 1 auto;
    z-index: 1;

    .ellipsis-row {
        display: flex;
        gap: 24px;
        justify-content: center;
    }

    .ellipse {
        height: 65px;
        padding: 8px 20px;
        border-radius: 40px;
        background: #19D6CC1A;
        display: flex;
        align-items: center;

        .ellipse-image {
            width: 87px;
            height: 65px;
            margin-right: 8px;
        }

        .ellipse-text {
            width: 140px;
            font-family: Manrope;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: white;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.text-content {
    max-width: 529px;
    width: 100%;
    text-align: right;

    .title {
        font-family: Manrope;
        font-size: 48px;
        font-weight: 700;
        line-height: 65.57px;
        color: white;
        margin-bottom: 12px;
    }

    .subtitle {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: white;
        margin-bottom: 20px;
    }
}


@media (max-width: 900px) {
    .content {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .ellipse-background {
        display: none;
    }

    .text-content {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text-content .subtitle,
    .text-content .title {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .ellipsis-container .ellipsis-row {
        display: flex;
        flex-direction: column;
    }
}
</style>