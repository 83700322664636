import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    activeDiscount: null,
    discountModalVisible: false,
  },
  mutations: {
    SET_ACTIVE_DISCOUNT(state, discount) {
      state.activeDiscount = discount;
    },
    SET_MODAL_VISIBILITY(state, isVisible) {
      state.discountModalVisible = isVisible;
    },
  },
  actions: {
    async fetchActiveDiscount({ commit }, currencyCode) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}post-purchase-discount?currency=${currencyCode}`
        );
        const data = response.data;
        if (data.status === "ERROR") {
          commit("SET_ACTIVE_DISCOUNT", null);
        } else {
          commit("SET_ACTIVE_DISCOUNT", {
            discountDuration: data.discountDuration,
            discountPercentage: data.discountPercentage,
            randomItems: data.randomItems || [],
          });
        }
      } catch (error) {
        console.error("Error fetching discount data:", error);
        commit("SET_ACTIVE_DISCOUNT", null);
      }
    },
    openDiscountModal({ commit }) {
      commit("SET_MODAL_VISIBILITY", true);
    },
    closeDiscountModal({ commit }) {
      commit("SET_MODAL_VISIBILITY", false);
    },
  },
  getters: {
    hasActiveDiscount: (state) => {
      return !!state.activeDiscount;
    },
    getDiscountPercentage: (state) =>
      state.activeDiscount ? state.activeDiscount.discountPercentage : 0,
    getDiscountDuration: (state) =>
      state.activeDiscount ? state.activeDiscount.discountDuration : 0,
    getProducts: (state) =>
      state.activeDiscount ? state.activeDiscount.randomItems : [],
    discountModalVisible: (state) => state.discountModalVisible,
  },
};
