<template>
    <div class="random-cases-section" v-if="randomCases && randomCases.length">
        <div class="wrapper">
            <div class="">
                <div class="slider-header">
                    <div class="left">
                        <!-- <div class="top">
                            <div class="new-badge">{{ $t(`New!`) }}</div>
                        </div> -->
                        <div class="bottom">
                            <h2 class="slider-title">{{ $t(`Packs`) }}</h2>
                            <!-- <i class="info-icon" v-tooltip="{
                                content: tooltipContent,
                                html: true,
                                triggers: ['click'],
                                placement: 'top',
                                autoHide: true,
                                theme: 'random-cases-tooltip'
                            }">
                                <img :src="require('@/assets/img/tooltip.svg')" alt="">
                            </i> -->
                        </div>
                    </div>
                    <div class="right">
                        <!-- <router-link to="/products/cases" class="link">{{ $t(`View all`) }}</router-link> -->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
            <div class="random-cases-slider">
                <swiper :modules="swiperModules"
                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                    :slidesPerView="'auto'" :spaceBetween="20" :slidesPerGroup="slidesToScroll()" @swiper="onSwiper">
                    <swiper-slide v-for="(card, index) in randomCases" :key="index" class="card" :data-slug="card.slug"
                        @click="handleClick(card)">
                        <div class="card-wrapper">
                            <div class="card-image-container">
                                <img :src="card.image_url" :alt="card.name" class="card-image" />
                            </div>
                            <div class="card-content">
                                <h3 class="card-title">{{ card.name }}</h3>

                                <p class="card-description">{{ card.description }}</p>

                                <button class="price-button">
                                    <span v-if="card.old_price" class="old-price">
                                        {{ card.old_price }} {{ currencySymbol }}
                                    </span>
                                    <span class="price">
                                        {{ card.price }} {{ currencySymbol }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
    name: 'RandomCasesSlider',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        currencySymbol: {
            type: String,
            required: true
        },
        currencyCode: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            swiperModules: [Navigation],
            tooltipContent: `
          <h2 class="tooltip-title">Random Packs</h2>
          <p class="tooltip-description">
            Please be advised that items obtained from these cases are distributed on a purely random basis. The value of the items you receive can vary significantly, generally ranging from equal to the cost of the case itself to potentially slightly exceeding the case's value. It's important to understand that due to the random nature of item distribution, we cannot guarantee the exact value or specific items you will receive from any given case. By purchasing a case, you acknowledge and accept these terms and conditions of the platform. If you have any questions or concerns, please contact our customer support before making a purchase.
          </p>
        `,
            randomCases: [],
            isDragging: false,
            swiper: null,

        }
    },
    computed: {
        //   ...mapGetters("app", ["currentCurrencySymbol", "currentCurrency"]),
    },
    watch: {
        currencyCode() {
            this.fetchRandomCases();
        },
        watch: {
            slidesToScroll() {
                this.$nextTick(() => {
                    this.handleWindowSizeChange();
                });
            },
        },
    },
    created() {
        this.fetchRandomCases();
        window.addEventListener("resize", this.handleWindowSizeChange);
    },
    mounted() {
    },
    unmounted() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        handleWindowSizeChange() {
            if (this.swiper) {
                // this.swiper.params.slidesPerView = this.slidesToScroll();
                this.swiper.params.slidesPerGroup = this.slidesToScroll();
                this.swiper.update();
            }
        },
        slidesToScroll() {
            let containerWidth;
            if (innerWidth >= 1200) {
                containerWidth = 1200; // fixed container width for large screens
            } else {
                containerWidth = innerWidth - 32;
            }
            const cardWidth = 303;
            const gap = 20;
            const slidesToShow = Math.floor((containerWidth + gap) / (cardWidth + gap));
            return slidesToShow;
        },
        handleClick(item) {
            this.$emit('goToProduct', item);
        },
        async fetchRandomCases() {
            try {
                const response = await this.$http.get(process.env.VUE_APP_API + 'packs' + '?currency=' + this.currencyCode);
                if (response.status === 200) {
                    this.randomCases = response.data.payload;
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    this.$parent.openSignInModal();
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.random-cases-section {
    // margin-top: 160px;

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 69px;
        margin-bottom: 40px;

        .left {

            .bottom {
                display: flex;
                gap: 10px;
            }

            .slider-title {
                font-weight: 600;
                font-size: calc(clamp(1.313rem, 0.83rem + 2.411vw, 3rem));
                color: white;
            }

            .info-icon {
                height: 100%;
            }

            .info-icon img {
                display: block;
                cursor: pointer;
            }
        }

        .right {
            position: relative;
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .link {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #fff;
        }

    }
}


.swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


.swiper-button-next,
.swiper-button-prev {
    position: relative;
    transform: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: #19d6cc;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    content: url('@/assets/img/arrow-right-24.svg');
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 0px;
    display: inline-block;
}

.swiper-button-prev:after {
    content: url('@/assets/img/arrow-left-24.svg');
}


.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 287px;
    background: transparent;
    border: none;
    padding: 16px;
    cursor: pointer;

    .card-image-container {
        width: 180px;
        height: 180px;
        display: flex;
        justify-content: center;

        .card-image {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .card-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            text-align: center;
            color: white;
            margin: 0;
            margin-top: 16px;
        }

        .card-description {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            color: white;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .price-button {
            max-width: 185px;
            width: fit-content;
            height: 36px;
            padding: 4.5px 24px;
            border-radius: 40px;
            border: 1px solid #19d6cc;
            background: transparent;
            font-size: 20px;
            font-weight: 600;
            line-height: 27px;
            text-align: center;
            color: #19d6cc;
            margin-top: 16px;
            cursor: pointer;

            display: flex;
            align-items: center;
            gap: 8px;

            .old-price {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                text-decoration: line-through;
            }

            .price {
                font-size: 20px;
                font-weight: 700;
                line-height: 27px;
                text-align: center;
                color: #19d6cc;
            }
        }
    }
}

@media (max-width: 1200px) {
    .swiper-button-prev {
        left: 0px;
    }

    .swiper-button-next {
        right: 0px;
    }
}
</style>