<template>
    <main class="support-page">
        <section class="section support-page">
            <div class="wrapper">
                <template v-if="faqData && faqData.length > 0">
                    <div v-for="(category, index) in faqData" :key="index" class="faq-category-section">
                        <div class="wrapper">
                            <FaqSection :title="category.category" :faq="category.questions" />
                        </div>
                    </div>
                </template>
                <div v-else-if="!isFetchingData && !faqData" class="no-faq-message">
                    {{ $t('No FAQs found.') }}
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import FaqSection from '@/components/FaqSection.vue'
export default {
    components: {
        FaqSection,
    },
    data() {
        return {
            faqData: null,
            isFetchingData: true,
        };
    },
    computed: {
        ...mapGetters('app', ['currentLanguage']),
    },
    watch: {
        currentLanguage() {
            this.fetchFaqData();
        }
    },
    mounted() {
        this.fetchFaqData();
    },
    methods: {
        fetchFaqData() {
            this.isFetchingData = true;
            this.$http.get(`${process.env.VUE_APP_API}faqs`, {
                params: {
                    include_categories: true,
                    lang_id: this.currentLanguage
                }
            })
                .then(response => {
                    if (response && response.data) {
                        this.faqData = response.data.payload;
                    } else {
                        console.error('Invalid response data:', response);
                    }
                })
                .catch(error => {
                    console.error('Error fetching FAQ data:', error);
                })
                .finally(() => {
                    this.isFetchingData = false;
                });
        },
    },
};
</script>

<style scoped>
.support-page {}

.section .wrapper {
    padding-bottom: 0;
}

.title.big {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 40px;
}

.faq-category-section {
    margin-bottom: 80px;
}

.no-faq-message {
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}
</style>