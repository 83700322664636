<template>
    <div class="stat-block">
        <div class="stat-value">
            {{ value }}
        </div>
        <div class="stat-label">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatBlock',
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>
.wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(223px, 1fr));
}

.stat-block {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #46566A;
    background-color: #1E2949;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;
}

.stat-value {
    font-family: 'Manrope', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    padding: 0 10px;
    border-radius: 4px;
    color: #19D6CC;
    min-width: 119px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    max-width: 208px;
}

@media (max-width: 768px) {
    .stat-block {}

    .stat-value {
        font-size: 32px;
        line-height: 40px;
    }

    .stat-label {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 600px) {
    .stat-block {}
}
</style>