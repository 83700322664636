<template>
  <section class="section whats-new-section" v-if="news && news.length">
    <div class="wrapper">
      <div class="header">
        <h2 class="section-title">What’s new?</h2>
        <router-link to="/news" class="view-all-link">View all</router-link>
      </div>
      <swiper :slides-per-view="'auto'" :space-between="15" :free-mode="true">
        <swiper-slide v-for="elem in news" :key="elem.id" :data-external-url="elem.external_url"
          @click="handleSwiperClick">
          <div class="card">
            <img :src="elem.image_url" :alt="elem.title" class="card-image">
            <h3 class="card-title" :title="elem.title">{{ elem.title }}</h3>
            <p class="card-date">{{ elem.date }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';

export default {
  name: 'WhatsNew',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      news: [],
    };
  },
  computed: {
    ...mapGetters('app', ['currentLanguage']),
  },
  mounted() {
    this.fetchNews();
  },
  watch: {
    currentLanguage() {
      this.fetchNews();
    }
  },
  methods: {
    fetchNews() {
      this.$http.get(`${process.env.VUE_APP_API}news`, {
        params: {
          // limit: 5,
          lang_id: this.currentLanguage
        }
      })
        .then(response => {
          this.news = response.data.payload;
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    },
    handleSwiperClick(event) {
      const clickedSlide = event.target.closest('.swiper-slide');
      if (clickedSlide) {
        const externalUrl = clickedSlide.dataset.externalUrl;

        if (externalUrl) {
          window.open(externalUrl, '_blank');
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.whats-new-section {
  color: #FFFFFF;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 65.57px;
  text-align: left;
}

.view-all-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #19D6CC;
  text-decoration: none;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  width: 228px;
}

.card {
  width: 228px;
  padding: 24px;
  border-radius: 8px;
  background: #1E2949;
  border: 1px solid #46566A;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .card-image {
    width: 180px;
    height: 140px;
    border-radius: 8px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .card-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 12px;
    margin-bottom: 24px;
    height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-date {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #19D6CC;
  }
}
</style>