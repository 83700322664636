<template>
  <main class="home-page">
    <section class="section hero-section">
      <img src="./../assets/img/hero-ellipse.png" alt="Ellipse Background" class="hero-image hero-ellipse">
      <div class="wrapper">
        <img src="./../assets/img/hero-top.png" alt="Hero Top" class="hero-image hero-top">
        <img src="./../assets/img/hero-middle.png" alt="Hero Middle" class="hero-image hero-middle">
        <img src="./../assets/img/hero-bottom.png" alt="Hero Bottom" class="hero-image hero-bottom">

        <div class="left">
          <div class="title big">
            {{ $t('The Ultimate Marketplace for CS2, TF2') }}
          </div>
          <div class="desc">
            {{ $t('Become part of the best trading community and transform your inventory with top tier items') }}
          </div>

          <button v-if="!isAuth" class="button home" @click="$emit('openSignUp')">
            {{ $t('Get Started') }}
          </button>

          <router-link v-if="isAuth" to="/products/all" class="button home">
            {{ $t('Get Started') }}
          </router-link>

        </div>
        <div class="right">
        </div>
      </div>
    </section>

    <StatSection />

    <ProductCarousel title="Top deals" apiEndpoint="items/rand-type-items" showAllLink="/products/all"
    @goToProduct="goToProduct" />

    <!-- <section class="section about-section">
      <div class="wrapper">
        <div class="title">
          {{ $t('About us') }}
        </div>
        <div class="item item-main">
          <div class="title small">
            Topnotch trading experience for every CS2 fan
          </div>
        </div>
        <div class="flex">
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Extensive collection') }}</b>
            </div>
            <div class="desc big blue">
              01
            </div>
          </div>
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Customer-centric experience') }}</b>
            </div>
            <div class="desc big blue">
              02
            </div>
          </div>
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Secure and seamless transactions') }}</b>
            </div>
            <div class="desc big blue">
              03
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <UnleashSection />

    <!-- <section class="section home-products-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            {{ $t('Hot deals') }}
          </div>
          <router-link to="/products/all" class="desc blue">
            {{ $t('View all') }}
          </router-link>
        </div>
        <Transition>
          <div v-if="saleProducts && saleProducts.length" class="products">
            <div v-for="(item, i) in saleProducts.slice(0, 5)" :key="i" class="item">
              <ProductCard :item="item" :currencySymbol="currencySymbol" :sale="true" @goToProduct="goToProduct" />
            </div>
          </div>
        </Transition>
      </div>
    </section> -->

    <!-- <section class="section last-sales-section">
      <LastSales :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
    </section> -->

    <section class="section packs-of-random-section">
      <RandomCasesSlider :currencySymbol="currencySymbol" :currencyCode="currencyCode" @goToProduct="goToProduct" />
    </section>

    <SecureAndAffordable />

    <!-- <section class="section payment-methods-section">
      <div class="wrapper">
        <div class="title">
          {{ $t('Variety of supported payment methods') }}
        </div>
        <div class="list">
          <div class="item">
            <img src="./../assets/img/methods/shield.svg" class="img">
            <div class="title small">
              {{ $t('Coming soon...') }}
            </div>
            <div class="desc blue">
              {{ $t('Fast and simple for your perfect experience') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/mc.svg" class="img">
            <div class="title small">
              {{ $t('Mastercard') }}
            </div>
            <div class="desc blue">
              {{ $t('World leader in digital payments') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/visa.svg" class="img">
            <div class="title small">
              {{ $t('Visa') }}
            </div>
            <div class="desc blue">
              {{ $t('Accepted in 210+ Countries') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/shield.svg" class="img">
            <div class="title small">
              {{ $t('Coming soon...') }}
            </div>
            <div class="desc blue">
              {{ $t('Fast and simple for your perfect experience') }}
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <FeaturesSection />

    <section class="section support-page" v-if="popularFaq && popularFaq.length">
      <div class="wrapper">
        <FaqSection :faq="popularFaq" title="Popular Questions" />
      </div>
    </section>

    <WhatsNew />

  </main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import RandomCasesSlider from "../components/RandomCasesSlider.vue";
// import LastSales from "../components/LastSales.vue";
import StatSection from "../components/StatSection.vue";
import UnleashSection from "../components/UnleashSection.vue";
import SecureAndAffordable from "../components/SecureAndAffordable.vue";
import FeaturesSection from "../components/FeaturesSection.vue";
import WhatsNew from "../components/WhatsNew.vue";
import FaqSection from "../components/FaqSection.vue";
import ProductCarousel from '@/components/ProductCarousel.vue';
export default {
  name: 'HomePage',
  components: {
    RandomCasesSlider,
    // LastSales,
    StatSection,
    UnleashSection,
    SecureAndAffordable,
    FeaturesSection,
    WhatsNew,
    FaqSection,
    ProductCarousel
  },
  props: {
    currencySymbol: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      saleProducts: [],
      newArrivals: [],
      activePanels: [],
      popularFaq: []
    }
  },
  computed: {
    ...mapGetters('app', ['currentLanguage']),
  },
  watch: {
    currencyCode() {
      this.getNewArrivals();
      this.getSale();
    },
  },
  async mounted() {
    this.getSale();
    this.getNewArrivals();
    this.getFaq();
    if (this.isAuth) {
      await this.fetchActiveDiscount(this.currencyCode);
      if (this.hasActiveDiscount) {
        this.openDiscountModal();
      }
    }
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faqs/popular' + '?lang_id=' + this.currentLanguage)
        .then((res) => {
          this.popularFaq = res.data.payload
        })
        .catch(() => {

        })
    },
    getNewArrivals() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currencyCode)
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch(() => {
        })
    },
    getSale() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?sale=true&currency=' + this.currencyCode)
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch(() => {
        })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>

<style scoped>
</style>